import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import {get} from 'lodash';

import {FormikNumberInput, FormikSelect, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {form4023Schema} from '../../schemas';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../types';
import {formatInteger, sum} from '../../utils';
import {useUnsavedChangesWarning} from '../../hooks';

const CLASSIFICATIONS: {[classificationName: string]: number} = {
  agricultural: 100,
  commercial: 200,
  industrial: 300,
  residential: 400,
  timberCutover: 500,
  developmental: 600
};

const CLASSIFICATIONS_PERSONAL: {[classificationName: string]: number} = {
  agricultural: 150,
  commercial: 250,
  industrial: 350,
  residential: 450,
  utility: 550
};

type RealPropertyClassification = 'agricultural'
                                  | 'residential'
                                  | 'industrial'
                                  | 'commercial'
                                  | 'timberCutover'
                                  | 'developmental';
type PersonalPropertyClassification = 'agricultural' | 'residential' | 'industrial' | 'commercial' | 'utility';

const tabs = [
  {value: 'realProperty', displayValue: 'Real Property'},
  {value: 'personalProperty', displayValue: 'Personal Property'}
];

const getClassificationKeys = (classification: string, type?: string) => {
  const section = type === 'personalProperty' ? CLASSIFICATIONS_PERSONAL[classification] : CLASSIFICATIONS[classification];
  return [section + 1, section + 2, section + 3, section + 4, section + 5, section + 6, section + 7, section + 8, section + 9].map((s) => s.toString());
};

const formatAdjustmentValue = (value?: number | null) => {
  return value === null || value === undefined ? null : value > 0 ? '+' + value.toLocaleString() : value.toLocaleString();
};

const mapRealProperty = (form4023: forms.Form4023Dto,
                         classification: RealPropertyClassification,
                         section: number | string) => {
  return {
    numberOfParcels: form4023?.realProperty?.[classification]?.[section]?.numberOfParcels ?? 0,
    assessedValue: form4023?.realProperty?.[classification]?.[section]?.assessedValue ?? null,
    trueCashValue: form4023?.realProperty?.[classification]?.[section]?.trueCashValue ?? null,
    remarks: form4023?.realProperty?.[classification]?.[section]?.remarks ?? ''
  };
};

const mapPersonalProperty = (form4023: forms.Form4023Dto,
                             classification: PersonalPropertyClassification,
                             section: string | number) => {
  return {
    numberOfParcels: form4023?.personalProperty?.[classification]?.[section]?.numberOfParcels ?? 0,
    assessedValue: form4023?.personalProperty?.[classification]?.[section]?.assessedValue ?? null,
    trueCashValue: form4023?.personalProperty?.[classification]?.[section]?.trueCashValue ?? null,
    remarks: form4023?.personalProperty?.[classification]?.[section]?.remarks ?? ''
  };
};

const mapProperty = (form4023: forms.Form4023Dto,
                     key: string,
                     classification: PersonalPropertyClassification | RealPropertyClassification,
                     section: string | number) => {
  return key === 'realProperty' ? mapRealProperty(form4023, classification as RealPropertyClassification, section) :
    mapPersonalProperty(form4023, classification as PersonalPropertyClassification, section);
};

const formatRatio = (val: number) => (val).toFixed(2) + '%';

const calculateRatio = (assessedValue: number | string, trueCashValue: number | string) => {
  if (trueCashValue === '' || assessedValue === 0 || trueCashValue === 0 ||
    !isFinite(assessedValue as number) ||
    !isFinite(trueCashValue as number)) {
    return 0;
  } else {
    return (assessedValue as number / (trueCashValue as number) * 100);
  }
};

const trimDecimal = (num: number, dec = 2) => Number(num.toFixed(dec));

const calculateClassification = (formikProps: FormikProps<any>, classification: string, type = 'realProperty') => {
  const section = type === 'realProperty' ? CLASSIFICATIONS[classification] : CLASSIFICATIONS_PERSONAL[classification];
  const sectionKeys = [section + 1, section + 2, section + 3, section + 4, section + 5, section + 6, section + 7, section + 8].map((s) => s.toString());
  const numberOfParcels = Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.numberOfParcels`, 0));

  let data: {[key: string]: {[key: string]: any}} = {
    [sectionKeys[0]]: {
      ratio: !isFinite(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`)) ||
      !isFinite(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`)) ||
      Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`)) === 0 ||
      get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`) === null ? 0 :
        trimDecimal(calculateRatio(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`, 0),
          get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 1)))
    }
  };

  const line1AssessedValue = Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`, 0));
  const line2AssessedValue = Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[1]}.assessedValue`, 0));
  const line2AssessedValueEqualToLine1 = line1AssessedValue === line2AssessedValue;
  const line1TCV = get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0) || 0;

  data[sectionKeys[1]] = {
    ratio: data[sectionKeys[0]].ratio,
    trueCashValue: line2AssessedValueEqualToLine1 ? line1TCV : (data[sectionKeys[0]].ratio === 0 ? 0 :
      Math.round(100 * line2AssessedValue / data[sectionKeys[0]].ratio))
  };

  data[sectionKeys[2]] = {
    assessedValue: !isFinite(Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`, 0))) ? 0 :
      get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`, 0) - get(formikProps.values, `${type}.${classification}.${sectionKeys[1]}.assessedValue`, 1),
    ratio: get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0) === null ||
    Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`)) === 0 ? 0 :
      calculateRatio(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.assessedValue`, 0),
        get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0)),
    trueCashValue: !isNaN(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0)) ?
      Math.round(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0) - data[sectionKeys[1]].trueCashValue) : 0
  };

  const line4AssessedValue = data[sectionKeys[2]].assessedValue === 0 ? 0 : get(formikProps.values, `${type}.${classification}.${sectionKeys[3]}.assessedValue`, 0);

  data[sectionKeys[3]] = {
    assessedValue: line4AssessedValue
  };

  const line5AssessedValue = (line1AssessedValue > 0 && data[sectionKeys[2]].assessedValue === 0) ? 0 :
    data[sectionKeys[2]].assessedValue + get(formikProps.values, `${type}.${classification}.${sectionKeys[3]}.assessedValue`, 0);

  data[sectionKeys[4]] = {
    assessedValue: line5AssessedValue,
    trueCashValue: !isNaN(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0)) ?
      Math.round(get(formikProps.values, `${type}.${classification}.${sectionKeys[0]}.trueCashValue`, 0) - data[sectionKeys[1]].trueCashValue) : 0
  };

  data[sectionKeys[4]].ratio = 0;

  if (line4AssessedValue === 0 && line5AssessedValue === 0) {
    data[sectionKeys[4]].ratio = 50;
  } else if (line4AssessedValue === 0 && line1AssessedValue > 0 && numberOfParcels > 0) {
    data[sectionKeys[4]].ratio = data[sectionKeys[0]].ratio;
  } else {
    data[sectionKeys[4]].ratio = trimDecimal(calculateRatio(data[sectionKeys[4]].assessedValue, data[sectionKeys[4]].trueCashValue));
  }

  data[sectionKeys[5]] = {
    ratio: data[sectionKeys[4]].ratio,
    trueCashValue: line1AssessedValue === 0 ? Number(get(formikProps.values, `${type}.${classification}[${sectionKeys[5]}].assessedValue`, 0)) * 2 :
      (data[sectionKeys[4]].ratio === 0 ? 0 :
        Math.round(100 * get(formikProps.values, `${type}.${classification}[${sectionKeys[5]}].assessedValue`, 0) / data[sectionKeys[4]].ratio))
  };

  data.totals = {
    trueCashValue: !isFinite(Number(get(formikProps.values, `${type}.${classification}.${sectionKeys[6]}.trueCashValue`, 0))) ? 0 :
      data[sectionKeys[4]].trueCashValue + data[sectionKeys[5]].trueCashValue + Number(get(formikProps.values, `${type}.${classification}[${sectionKeys[6]}].trueCashValue`, 0)),
    assessedValue: !isFinite(formikProps.values[type][classification][sectionKeys[5]].assessedValue) ? 0 :
      data[sectionKeys[4]].assessedValue + formikProps.values[type][classification][sectionKeys[5]].assessedValue,
    ratio: 0,
    recommendedCev: 0,
    equalizationFactor: 0
  };

  data.totals.ratio = !isFinite(data.totals.trueCashValue) || !isFinite(data.totals.assessedValue) || data.totals.trueCashValue === 0 ? 0 :
    (data.totals.assessedValue / data.totals.trueCashValue) * 100;

  // Round to two decimal places
  data.totals.ratio = Math.round(100 * data.totals.ratio) / 100;

  data.totals.recommendedCev = !isFinite(data.totals.assessedValue) ||
  !isFinite(data.totals.trueCashValue) ? 0 :
    data.totals.ratio >= 49 && data.totals.ratio <= 50 ?
      data.totals.assessedValue : Math.round(data.totals.trueCashValue / 2);


  data.totals.equalizationFactor = !isFinite(data.totals.assessedValue) ||
  !isFinite(data.totals.trueCashValue) ||
  data.totals.assessedValue === 0 ? 0 :
    data.totals.ratio >= 49 && data.totals.ratio <= 50 ?
      `1.00000` : (data.totals.recommendedCev / data.totals.assessedValue).toFixed(5);

  return data;
};

const calculateFields = (formikProps: FormikProps<any>) => {
  let values = {
    realProperty: {
      agricultural: calculateClassification(formikProps, 'agricultural'),
      commercial: calculateClassification(formikProps, 'commercial'),
      industrial: calculateClassification(formikProps, 'industrial'),
      residential: calculateClassification(formikProps, 'residential'),
      timberCutover: calculateClassification(formikProps, 'timberCutover'),
      developmental: calculateClassification(formikProps, 'developmental'),
      totals: {
        numberOfParcels: 0,
        ratio: 0,
        trueCashValue: 0,
        trueCashValue50: 0,
        assessedValue: 0,
        equalizationFactor: '',
        recommendedCev: 0
      }
    },
    personalProperty: {
      agricultural: calculateClassification(formikProps, 'agricultural', 'personalProperty'),
      commercial: calculateClassification(formikProps, 'commercial', 'personalProperty'),
      industrial: calculateClassification(formikProps, 'industrial', 'personalProperty'),
      residential: calculateClassification(formikProps, 'residential', 'personalProperty'),
      utility: calculateClassification(formikProps, 'utility', 'personalProperty'),
      totals: {
        numberOfParcels: 0,
        ratio: 0,
        trueCashValue: 0,
        trueCashValue50: 0,
        assessedValue: 0,
        equalizationFactor: '',
        recommendedCev: 0
      }
    }
  };

  const realPropertyClassifications = Object.keys(values.realProperty).filter(key => key !== 'totals');
  const personalPropertyClassifications = Object.keys(values.personalProperty).filter(key => key !== 'totals');

  values.realProperty.totals = {
    numberOfParcels: realPropertyClassifications.map((classification) => {
      const keys = getClassificationKeys(classification);
      return formikProps.values.realProperty[classification][keys[7]].numberOfParcels;
    }).reduce((a, b) => a + b),
    assessedValue: realPropertyClassifications.map((classification) => {
      return values.realProperty[classification as RealPropertyClassification].totals.assessedValue || 0;
    }).reduce((a, b) => a + b),
    trueCashValue: realPropertyClassifications.map((classification) => {
      return Math.round(values.realProperty[classification as RealPropertyClassification].totals.trueCashValue) || 0;
    }).reduce((a, b) => a + b),
    trueCashValue50: 0,
    ratio: 0,
    equalizationFactor: '',
    recommendedCev: 0
  };

  values.realProperty.totals.trueCashValue50 = Math.round(values.realProperty.totals.trueCashValue / 2) || 0;

  values.realProperty.totals.ratio = values.realProperty.totals.trueCashValue === 0 ? 0 :
    100 * values.realProperty.totals.assessedValue / values.realProperty.totals.trueCashValue;

  // Round to two decimal places
  values.realProperty.totals.ratio = Math.round(100 * values.realProperty.totals.ratio) / 100;

  values.personalProperty.totals = {
    numberOfParcels: personalPropertyClassifications.map((classification) => {
      const keys = getClassificationKeys(classification, 'personalProperty');
      return formikProps.values.personalProperty[classification][keys[7]].numberOfParcels;
    }).reduce((a, b) => a + b),
    assessedValue: personalPropertyClassifications.map((classification) => {
      return values.personalProperty[classification as PersonalPropertyClassification].totals.assessedValue || 0;
    }).reduce((a, b) => a + b),
    trueCashValue: personalPropertyClassifications.map((classification) => {
      return Math.round(values.personalProperty[classification as PersonalPropertyClassification].totals.trueCashValue) || 0;
    }).reduce((a, b) => a + b),
    trueCashValue50: 0,
    ratio: 0,
    equalizationFactor: '',
    recommendedCev: 0
  };

  values.personalProperty.totals.trueCashValue50 = Math.round(values.personalProperty.totals.trueCashValue / 2) || 0;

  values.personalProperty.totals.ratio = values.personalProperty.totals.trueCashValue === 0 ? 0 :
    100 * values.personalProperty.totals.assessedValue / values.personalProperty.totals.trueCashValue;

  // Round to two decimal places
  values.personalProperty.totals.ratio = Math.round(100 * values.personalProperty.totals.ratio) / 100;

  values.personalProperty.totals.recommendedCev = !isFinite(values.personalProperty.totals.assessedValue) ||
  !isFinite(values.personalProperty.totals.trueCashValue) ? 0 :
    values.personalProperty.totals.ratio >= 49 && values.personalProperty.totals.ratio <= 50 ?
      values.personalProperty.totals.assessedValue : Math.round(values.personalProperty.totals.trueCashValue / 2);

  values.realProperty.totals.recommendedCev = sum(Object.keys(values.realProperty)
    .filter(classification => classification !== 'totals')
    .map(classification => Math.round(values.realProperty[classification as RealPropertyClassification].totals.recommendedCev) || 0));

  values.personalProperty.totals.equalizationFactor = values.personalProperty.totals.ratio >= 49 && values.personalProperty.totals.ratio <= 50 ?
    '1.00000' : (isFinite(values.personalProperty.totals.assessedValue) &&
    values.personalProperty.totals.assessedValue !== 0 ?
      values.personalProperty.totals.recommendedCev / values.personalProperty.totals.assessedValue : 0).toFixed(5);

  values.realProperty.totals.equalizationFactor = values.realProperty.totals.ratio >= 49 && values.realProperty.totals.ratio <= 50 ?
    '1.00000' : (isFinite(values.realProperty.totals.assessedValue) &&
    values.realProperty.totals.assessedValue !== 0 ?
      values.realProperty.totals.recommendedCev / values.realProperty.totals.assessedValue : 0).toFixed(5);

  return values;
};

const getSaveableData = (formikProps: FormikProps<forms.Form4023Dto>) => {
  const calculatedFields = calculateFields(formikProps);

  const realPropertyTotals = {
    'assessedValue108': calculatedFields.realProperty.agricultural.totals.assessedValue,
    'recommendedCev109': calculatedFields.realProperty.agricultural.totals.recommendedCev,
    'assessedValue208': calculatedFields.realProperty.commercial.totals.assessedValue,
    'recommendedCev209': calculatedFields.realProperty.commercial.totals.recommendedCev,
    'assessedValue308': calculatedFields.realProperty.industrial.totals.assessedValue,
    'recommendedCev309': calculatedFields.realProperty.industrial.totals.recommendedCev,
    'assessedValue408': calculatedFields.realProperty.residential.totals.assessedValue,
    'recommendedCev409': calculatedFields.realProperty.residential.totals.recommendedCev,
    'assessedValue508': calculatedFields.realProperty.timberCutover.totals.assessedValue,
    'recommendedCev509': calculatedFields.realProperty.timberCutover.totals.recommendedCev,
    'assessedValue608': calculatedFields.realProperty.developmental.totals.assessedValue,
    'recommendedCev609': calculatedFields.realProperty.developmental.totals.recommendedCev
  };

  const personalPropertyTotals = {
    'recommendedCev859': calculatedFields.personalProperty.totals.recommendedCev,
    'computedTcv859': calculatedFields.personalProperty.totals.trueCashValue50
  };

  formikProps.values.realProperty.totals = realPropertyTotals;
  formikProps.values.personalProperty.totals = personalPropertyTotals;

  return {
    type: 'FORM_4023' as const,
    realProperty: formikProps.values.realProperty,
    personalProperty: formikProps.values.personalProperty
  };
};

type Props = {
  form: LocalUnitFormDto
  loading: boolean
  onSave: (localUnitFormData: forms.LocalUnitFormData, afterSave?: () => void) => Promise<void>
  submitButtonText: string
  isStateUser: boolean
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
  isLocalUnitUser: boolean
}

const Form4023 = ({
                    form,
                    loading,
                    onSave,
                    submitButtonText,
                    isStateUser,
                    onAmend,
                    onResubmit,
                    onSubmit,
                    onAccept,
                    onReturn,
                    isLocalUnitUser
                  }: Props) => {
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();

  // Use latest form submission data if available for state users
  const form4023Dto = useMemo(() => (
    isStateUser && form.latestSubmissionData !== null ? form.latestSubmissionData : form.data
  ) as forms.Form4023Dto, [form, isStateUser]);
  const {selectedTab} = useTabNav();
  const [commentModalIsOpen, toggleCommentModal] = useState(false);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setResubmitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);

  const handleSave = useCallback((formikProps: FormikProps<forms.Form4023Dto>,
                                  afterSave?: () => void) => {
    return onSave(getSaveableData(formikProps), afterSave);
  }, [
    onSave
  ]);

  const handleAmend = useCallback((formikProps: FormikProps<forms.Form4023Dto>,
                                   amendmentRequest: AmendmentRequest) => {
    onAmend(amendmentRequest, getSaveableData(formikProps));
  }, [
    onAmend
  ]);

  const handleSubmit = useCallback((formikProps: FormikProps<forms.Form4023Dto>) => {
    return onSubmit(getSaveableData(formikProps));
  }, [
    onSubmit
  ]);

  const toggleSubmitModal = useCallback((confirmSubmit = false,
                                         formikProps?: FormikProps<forms.Form4023Dto>) => {
    if (confirmSubmit && formikProps) {
      handleSubmit(formikProps);
    }
    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    submitModalIsOpen,
    handleSubmit
  ]);

  const handleResubmit = useCallback((formikProps: FormikProps<forms.Form4023Dto>) => {
    return onResubmit(getSaveableData(formikProps));
  }, [
    onResubmit
  ]);

  const toggleResubmitModal = useCallback((confirmResubmit = false,
                                           formikProps?: FormikProps<forms.Form4023Dto>) => {
    if (confirmResubmit && formikProps) {
      handleResubmit(formikProps);
    }
    setResubmitModalIsOpen(!resubmitModalIsOpen);
  }, [
    resubmitModalIsOpen,
    handleResubmit
  ]);

  const handleAccept = useCallback(() => {
    onAccept();
  }, [
    onAccept
  ]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      handleAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [
    acceptModalIsOpen,
    handleAccept
  ]);

  const initialValues: forms.Form4023Dto = useMemo(() => ({
    type: 'FORM_4023' as const,
    realProperty: {
      agricultural: {
        101: mapProperty(form4023Dto, 'realProperty', 'agricultural', 101),
        102: mapProperty(form4023Dto, 'realProperty', 'agricultural', 102),
        103: mapProperty(form4023Dto, 'realProperty', 'agricultural', 103),
        104: mapProperty(form4023Dto, 'realProperty', 'agricultural', 104),
        105: mapProperty(form4023Dto, 'realProperty', 'agricultural', 105),
        106: mapProperty(form4023Dto, 'realProperty', 'agricultural', 106),
        107: mapProperty(form4023Dto, 'realProperty', 'agricultural', 107),
        108: mapProperty(form4023Dto, 'realProperty', 'agricultural', 108)
      },
      commercial: {
        201: mapProperty(form4023Dto, 'realProperty', 'commercial', 201),
        202: mapProperty(form4023Dto, 'realProperty', 'commercial', 202),
        203: mapProperty(form4023Dto, 'realProperty', 'commercial', 203),
        204: mapProperty(form4023Dto, 'realProperty', 'commercial', 204),
        205: mapProperty(form4023Dto, 'realProperty', 'commercial', 205),
        206: mapProperty(form4023Dto, 'realProperty', 'commercial', 206),
        207: mapProperty(form4023Dto, 'realProperty', 'commercial', 207),
        208: mapProperty(form4023Dto, 'realProperty', 'commercial', 208)
      },
      industrial: {
        301: mapProperty(form4023Dto, 'realProperty', 'industrial', 301),
        302: mapProperty(form4023Dto, 'realProperty', 'industrial', 302),
        303: mapProperty(form4023Dto, 'realProperty', 'industrial', 303),
        304: mapProperty(form4023Dto, 'realProperty', 'industrial', 304),
        305: mapProperty(form4023Dto, 'realProperty', 'industrial', 305),
        306: mapProperty(form4023Dto, 'realProperty', 'industrial', 306),
        307: mapProperty(form4023Dto, 'realProperty', 'industrial', 307),
        308: mapProperty(form4023Dto, 'realProperty', 'industrial', 308)
      },
      residential: {
        401: mapProperty(form4023Dto, 'realProperty', 'residential', 401),
        402: mapProperty(form4023Dto, 'realProperty', 'residential', 402),
        403: mapProperty(form4023Dto, 'realProperty', 'residential', 403),
        404: mapProperty(form4023Dto, 'realProperty', 'residential', 404),
        405: mapProperty(form4023Dto, 'realProperty', 'residential', 405),
        406: mapProperty(form4023Dto, 'realProperty', 'residential', 406),
        407: mapProperty(form4023Dto, 'realProperty', 'residential', 407),
        408: mapProperty(form4023Dto, 'realProperty', 'residential', 408)
      },
      timberCutover: {
        501: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 501),
        502: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 502),
        503: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 503),
        504: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 504),
        505: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 505),
        506: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 506),
        507: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 507),
        508: mapProperty(form4023Dto, 'realProperty', 'timberCutover', 508)
      },
      developmental: {
        601: mapProperty(form4023Dto, 'realProperty', 'developmental', 601),
        602: mapProperty(form4023Dto, 'realProperty', 'developmental', 602),
        603: mapProperty(form4023Dto, 'realProperty', 'developmental', 603),
        604: mapProperty(form4023Dto, 'realProperty', 'developmental', 604),
        605: mapProperty(form4023Dto, 'realProperty', 'developmental', 605),
        606: mapProperty(form4023Dto, 'realProperty', 'developmental', 606),
        607: mapProperty(form4023Dto, 'realProperty', 'developmental', 607),
        608: mapProperty(form4023Dto, 'realProperty', 'developmental', 608)
      }
    },
    personalProperty: {
      agricultural: {
        151: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 151),
        152: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 152),
        153: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 153),
        154: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 154),
        155: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 155),
        156: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 156),
        157: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 157),
        158: mapProperty(form4023Dto, 'personalProperty', 'agricultural', 158)
      },
      commercial: {
        251: mapProperty(form4023Dto, 'personalProperty', 'commercial', 251),
        252: mapProperty(form4023Dto, 'personalProperty', 'commercial', 252),
        253: mapProperty(form4023Dto, 'personalProperty', 'commercial', 253),
        254: mapProperty(form4023Dto, 'personalProperty', 'commercial', 254),
        255: mapProperty(form4023Dto, 'personalProperty', 'commercial', 255),
        256: mapProperty(form4023Dto, 'personalProperty', 'commercial', 256),
        257: mapProperty(form4023Dto, 'personalProperty', 'commercial', 257),
        258: mapProperty(form4023Dto, 'personalProperty', 'commercial', 258)
      },
      industrial: {
        351: mapProperty(form4023Dto, 'personalProperty', 'industrial', 351),
        352: mapProperty(form4023Dto, 'personalProperty', 'industrial', 352),
        353: mapProperty(form4023Dto, 'personalProperty', 'industrial', 353),
        354: mapProperty(form4023Dto, 'personalProperty', 'industrial', 354),
        355: mapProperty(form4023Dto, 'personalProperty', 'industrial', 355),
        356: mapProperty(form4023Dto, 'personalProperty', 'industrial', 356),
        357: mapProperty(form4023Dto, 'personalProperty', 'industrial', 357),
        358: mapProperty(form4023Dto, 'personalProperty', 'industrial', 358)
      },
      residential: {
        451: mapProperty(form4023Dto, 'personalProperty', 'residential', 451),
        452: mapProperty(form4023Dto, 'personalProperty', 'residential', 452),
        453: mapProperty(form4023Dto, 'personalProperty', 'residential', 453),
        454: mapProperty(form4023Dto, 'personalProperty', 'residential', 454),
        455: mapProperty(form4023Dto, 'personalProperty', 'residential', 455),
        456: mapProperty(form4023Dto, 'personalProperty', 'residential', 456),
        457: mapProperty(form4023Dto, 'personalProperty', 'residential', 457),
        458: mapProperty(form4023Dto, 'personalProperty', 'residential', 458)
      },
      utility: {
        551: mapProperty(form4023Dto, 'personalProperty', 'utility', 551),
        552: mapProperty(form4023Dto, 'personalProperty', 'utility', 552),
        553: mapProperty(form4023Dto, 'personalProperty', 'utility', 553),
        554: mapProperty(form4023Dto, 'personalProperty', 'utility', 554),
        555: mapProperty(form4023Dto, 'personalProperty', 'utility', 555),
        556: mapProperty(form4023Dto, 'personalProperty', 'utility', 556),
        557: mapProperty(form4023Dto, 'personalProperty', 'utility', 557),
        558: mapProperty(form4023Dto, 'personalProperty', 'utility', 558)
      }
    }
  }), [
    form4023Dto
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser && form.status !== 'IN_PROGRESS';
  }, [
    isStateUser,
    form.status
  ]);

  const showReadOnlyView = useMemo(() => {
    return isStateUser || isLocalUnitUser || form.locked;
  }, [
    isStateUser,
    isLocalUnitUser,
    form.locked
  ]);

  const isSaveDisabled = useMemo(() => {
    return loading || form.locked;
  }, [
    loading,
    form.locked
  ]);

  return <div className="Form4023">
    <Formik initialValues={initialValues}
            validationSchema={form4023Schema}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const calculatedFields = calculateFields(formikProps);
        const classes = selectedTab === 'realProperty' ? CLASSIFICATIONS : CLASSIFICATIONS_PERSONAL;
        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <CardBody>
              <>
                {Object.keys(classes).map((classification) => {
                  const section = classes[classification];
                  const sectionKeys = [section + 1, section + 2, section + 3, section + 4, section + 5, section + 6, section + 7, section + 8, section + 9];
                  const formikValues = formikProps.values;
                  const values = selectedTab === 'realProperty' ? formikValues.realProperty[classification as RealPropertyClassification] :
                    formikValues.personalProperty[classification as PersonalPropertyClassification];
                  const calculatedValues = selectedTab === 'realProperty' ?
                    calculatedFields.realProperty[classification as RealPropertyClassification] :
                    calculatedFields.personalProperty[classification as PersonalPropertyClassification];
                  const classificationName = classification === 'timberCutover' ? 'Timber-Cutover' :
                    classification[0].toUpperCase() + classification.substring(1, classification.length);

                  return <div key={classification}>
                    <div className="mb-4">
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th className="text-primary align-middle font-weight-bold w-10">
                              {classificationName}
                            </th>
                            <th style={{width: '5%'}}/>
                            <th className="text-primary align-middle font-weight-bold text-center w-20">Number of Parcels</th>
                            <th className="text-primary align-middle font-weight-bold text-center w-20">Assessed Value</th>
                            <th className="text-primary align-middle font-weight-bold text-center" style={{width: '12%'}}>% Ratio</th>
                            <th className="text-primary align-middle font-weight-bold text-center w-20">True Cash Value</th>
                            <th className="text-primary align-middle font-weight-bold text-center" style={{width: '13%'}}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={sectionKeys[0]}>
                            <td className="align-middle text-primary font-weight-bold">
                              {sectionKeys[0]}
                            </td>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {formatInteger(values[sectionKeys[0]]?.numberOfParcels)}
                            </td>
                            <td className="align-middle text-center">
                              {showReadOnlyView && formatInteger(values[sectionKeys[0]]?.assessedValue)}
                              {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                                       className="text-center"
                                                                       ariaLabel="Assessed Value"
                                                                       disableFloatingLabel={true}
                                                                       formGroupClass="mb-0"
                                                                       onChange={() => setHasUnsavedChanges(true)}
                                                                       name={`${selectedTab}.${classification}[${sectionKeys[0]}].assessedValue`}/>}
                            </td>
                            <td className="align-middle text-center">
                              {formatRatio(calculatedValues[sectionKeys[0]].ratio)}
                            </td>
                            <td className="align-middle text-center">
                              {showReadOnlyView && formatInteger(values[sectionKeys[0]]?.trueCashValue)}
                              {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                                       className="text-center"
                                                                       disableFloatingLabel={true}
                                                                       ariaLabel="True Cash Value"
                                                                       formGroupClass="mb-0"
                                                                       onChange={() => setHasUnsavedChanges(true)}
                                                                       name={`${selectedTab}.${classification}[${sectionKeys[0]}].trueCashValue`}/>}
                            </td>
                            <td className="align-middle">
                              {values[sectionKeys[0]].remarks}
                            </td>
                          </tr>
                          <tr key={sectionKeys[1]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[1]}
                            </td>
                            <td className="align-middle">Loss</td>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {formatInteger(values[sectionKeys[1]]?.assessedValue)}
                            </td>
                            <td className="align-middle text-center">
                              {formatRatio(calculatedValues[sectionKeys[1]].ratio)}
                            </td>
                            <td className="align-middle text-center">
                              {formatInteger(calculatedValues[sectionKeys[1]].trueCashValue)}
                            </td>
                            <td className="align-middle"/>
                          </tr>
                          <tr key={sectionKeys[2]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[2]}
                            </td>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {formatInteger(calculatedValues[sectionKeys[2]].assessedValue)}
                            </td>
                            <td className="align-middle text-center">
                              {formatRatio(calculatedValues[sectionKeys[2]].ratio)}
                            </td>
                            <td className="align-middle  text-center">
                              {formatInteger(calculatedValues[sectionKeys[2]].trueCashValue)}
                            </td>
                            <td className="align-middle"/>
                          </tr>
                          <tr key={sectionKeys[3]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[3]}
                            </td>
                            <td className="align-middle">Adjustment</td>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {
                                calculatedValues[sectionKeys[3]].assessedValue === null ?
                                  0 : formatAdjustmentValue(calculatedValues[sectionKeys[3]].assessedValue)
                              }
                            </td>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                          </tr>
                          <tr key={sectionKeys[4]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[4]}
                            </td>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {formatInteger(calculatedValues[sectionKeys[4]].assessedValue)}
                            </td>
                            <td className="align-middle text-center">
                              {formatRatio(calculatedValues[sectionKeys[4]].ratio)}
                            </td>
                            <td className="align-middle text-center">
                              {formatInteger(calculatedValues[sectionKeys[4]].trueCashValue)}
                            </td>
                            <td className="align-middle"/>
                          </tr>
                          <tr key={sectionKeys[5]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[5]}
                            </td>
                            <td className="align-middle">New</td>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {values[sectionKeys[5]].assessedValue != null ? formatInteger(values[sectionKeys[5]]?.assessedValue) : 0}
                            </td>
                            <td className="align-middle text-center">
                              {formatRatio(calculatedValues[sectionKeys[5]].ratio)}
                            </td>
                            <td className="align-middle text-center">
                              {
                                calculatedValues[sectionKeys[5]].trueCashValue !== null ?
                                  formatInteger(calculatedValues[sectionKeys[5]].trueCashValue) : 0
                              }
                            </td>
                            <td className="align-middle"/>
                          </tr>
                          <tr key={sectionKeys[6]}>
                            <td className="align-middle font-weight-bold text-primary">
                              {sectionKeys[6]}
                            </td>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle"/>
                            <td className="align-middle text-center">
                              {showReadOnlyView && formatInteger(values[sectionKeys[6]]?.trueCashValue)}
                              {!showReadOnlyView && <FormikNumberInput className="text-center"
                                                                       maxLength="15"
                                                                       ariaLabel="True Cash Value"
                                                                       formGroupClass="mb-0"
                                                                       disableFloatingLabel={true}
                                                                       aria-required={values[sectionKeys[6]].remarks === 'RA'}
                                                                       onChange={() => setHasUnsavedChanges(true)}
                                                                       name={`${selectedTab}.${classification}[${sectionKeys[6]}].trueCashValue`}/>
                              }
                            </td>
                            <td className="align-middle">
                              {showReadOnlyView && values[sectionKeys[6]].remarks}
                              {!showReadOnlyView && <FormikSelect formGroupClass="mb-0"
                                                                  ariaLabel="Remarks"
                                                                  disableFloatingLabel={true}
                                                                  onChange={() => setHasUnsavedChanges(true)}
                                                                  name={`${selectedTab}.${classification}[${sectionKeys[6]}].remarks`}>
                                <option value="">Select</option>
                                <option value="RA">RA - Reappraisal</option>
                                <option value="OTH">OTH - Other</option>
                              </FormikSelect>}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="align-middle">
                            <td className="text-primary font-weight-bold">
                              {sectionKeys[7]}
                            </td>
                            <td className="text-primary font-weight-bold">Total</td>
                            <td className="text-center font-weight-bold">
                              {values[sectionKeys[7]].numberOfParcels === null ? 0 :
                                formatInteger(values[sectionKeys[7]]?.numberOfParcels)}
                            </td>
                            <td className="text-center font-weight-bold">
                              {formatInteger(calculatedValues.totals.assessedValue)}
                            </td>
                            <td className="text-center font-weight-bold">
                              {formatRatio(calculatedValues.totals.ratio)}
                            </td>
                            <td className="text-center font-weight-bold">
                              {formatInteger(calculatedValues.totals.trueCashValue)}
                            </td>
                            <td/>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                    {selectedTab === 'realProperty' && <>
                      <Card className="mb-4">
                        <CardHeader>
                          {sectionKeys[8]} {classificationName}
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xs="8" sm="6">
                              Computed 50% of TCV Real {classificationName}
                            </Col>
                            <Col xs="4" sm="6" className="text-right">
                              {formatInteger(Math.round(calculatedValues.totals.trueCashValue / 2))}
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col xs="8" sm="6">
                              Recommended CEV Real {classificationName}
                            </Col>
                            <Col xs="4" sm="6" className="text-right">
                              {formatInteger(Math.round(calculatedValues.totals.recommendedCev))}
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col xs="8" sm="6">
                              Equalization Factor
                            </Col>
                            <Col xs="4" sm="6" className="text-right">
                              {calculatedValues.totals.equalizationFactor}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>}
                  </div>;
                })}
                <div className="mb-4">
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="align-middle text-primary font-weight-bold">
                          {selectedTab === 'personalProperty' ? 'Total Personal' : 'Total Real'}
                        </th>
                        <th className="align-middle text-primary text-center">Number of Parcels</th>
                        <th className="align-middle text-primary text-center">Assessed Value</th>
                        <th className="align-middle text-primary text-center">% Ratio</th>
                        <th className="align-middle text-primary text-center">True Cash Value</th>
                        <th className="align-middle text-primary text-center">Factor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-middle text-primary font-weight-bold">
                          {selectedTab === 'personalProperty' ? 850 : 800}
                        </td>
                        <td className="align-middle text-center">
                          {formatInteger(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.numberOfParcels)}
                        </td>
                        <td className="align-middle text-center">
                          {formatInteger(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.assessedValue)}
                        </td>
                        <td className="align-middle text-center">
                          {formatRatio(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.ratio)}
                        </td>
                        <td className="align-middle text-center">
                          {formatInteger(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.trueCashValue)}
                        </td>
                        <td className="align-middle text-center">
                          {calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.equalizationFactor}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Card>
                  <CardHeader>
                    {selectedTab === 'personalProperty' ? '859 Totals' : '809 Totals'}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="8" sm="6">
                        {selectedTab === 'personalProperty' ?
                          'Computed 50% of TCV, Total Personal Property' :
                          'Computed 50% of TCV, Total 6 Classes Real'
                        }
                      </Col>
                      <Col xs="4" sm="6" className="text-right">
                        {formatInteger(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.trueCashValue50)}
                      </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col xs="8" sm="6">
                        {selectedTab === 'personalProperty' ?
                          'Recommended CEV, Total Personal Property' :
                          'Recommended CEV, Total 6 Classes Real'
                        }
                      </Col>
                      <Col xs="4" sm="6" className="text-right">
                        {formatInteger(calculatedFields[selectedTab as 'realProperty' | 'personalProperty'].totals.recommendedCev)}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            </CardBody>
          </Card>
          <FormHistoryTable items={form.formHistory}/>

          {!isStateUser && !isLocalUnitUser && <>
            <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps, afterSave)}/>

            <FormModals form={form}
                        resubmitFormModalIsOpen={resubmitModalIsOpen}
                        submitFormModalIsOpen={submitModalIsOpen}
                        amendModalIsOpen={commentModalIsOpen}
                        onSubmit={() => toggleSubmitModal(true, formikProps)}
                        onCancelSubmit={() => toggleSubmitModal(false)}
                        onResubmit={() => toggleResubmitModal(true, formikProps)}
                        onResubmitCancel={() => toggleResubmitModal(false)}
                        onAmend={(values) => handleAmend(formikProps, values)}
                        toggleAmend={() => toggleCommentModal(false)}/>

            <FormActionButtons submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || form.locked}
                               saveDisabled={isSaveDisabled}
                               submitButtonText={submitButtonText}
                               onSave={() => handleSave(formikProps)}
                               showSubmitButton={!isLocalUnitUser}
                               onToggleCommentModal={() => toggleCommentModal(true)}
                               onToggleResubmitModal={() => toggleResubmitModal(false, formikProps)}
                               onToggleSubmitModal={() => toggleSubmitModal(false, formikProps)}/>
          </>}

          {shouldDisplayStateFormButtons && <>
            <StateFormButtons loading={loading}
                              onReturnClick={() => toggleCommentModal(true)}
                              onAcceptClick={() => toggleAcceptModal()}/>
            <StateFormModals onReturn={onReturn}
                             returnModalIsOpen={commentModalIsOpen}
                             onReturnCancel={() => toggleCommentModal(false)}
                             onAccept={() => toggleAcceptModal(true)}
                             acceptModalIsOpen={acceptModalIsOpen}
                             onAcceptCancel={() => toggleAcceptModal()}
                             form={form}/>
          </>}

          {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                       isStateUser={isStateUser}/>}
        </>;
      }}
    </Formik>

  </div>;
};
export default withTabNav(Form4023, {tabs});