import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {useCallback, useEffect} from 'react';
import {useBlocker} from 'react-router-dom';

import {useAlerts} from '@reasoncorp/kyber-js';

import {useUnsavedChangesWarning} from '../../hooks';
import * as messages from '../../messages';

type Props = {
  onSave: ((afterSave: () => void) => Promise<void>) | ((afterSave: () => void) => void)
}

const UnsavedChangesWarningModal = ({
                                      onSave
                                    }: Props) => {
  const {
    hasUnsavedChanges,
    isWarningModalOpen,
    setIsWarningModalOpen,
    setHasUnsavedChanges
  } = useUnsavedChangesWarning();
  const {showErrorAlert} = useAlerts();

  const blocker = useBlocker(({currentLocation, nextLocation}) => {
    return hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (blocker.state === 'blocked' && hasUnsavedChanges) {
      setIsWarningModalOpen(true);
    }
    if (blocker.state === 'blocked' && !hasUnsavedChanges) {
      blocker.reset?.();
    }
  }, [
    setIsWarningModalOpen,
    hasUnsavedChanges,
    blocker
  ]);

  const handleDismiss = useCallback(() => {
    setIsWarningModalOpen(false);
    setHasUnsavedChanges(false);
    if (blocker.state === 'blocked') {
      blocker?.proceed?.();
    }
  }, [
    setIsWarningModalOpen,
    setHasUnsavedChanges,
    blocker
  ]);

  const handleCancel = useCallback(() => {
    setIsWarningModalOpen(false);
    blocker.reset?.();
  }, [
    blocker,
    setIsWarningModalOpen
  ]);

  const handleNavigateAfterSave = useCallback(() => {
    if (blocker.state === 'blocked') {
      blocker?.proceed?.();
    }
  }, [
    blocker
  ]);

  const handleSave = useCallback(async () => {
    try {
      setIsWarningModalOpen(false);
      await Promise.resolve(onSave(handleNavigateAfterSave));
    } catch (error) {
      showErrorAlert(messages.FORM_SAVE_FAILURE);
    }
  }, [
    handleNavigateAfterSave,
    showErrorAlert,
    setIsWarningModalOpen,
    onSave
  ]);

  return <Modal size="lg"
                isOpen={isWarningModalOpen}
                toggle={() => handleCancel()}
                autoFocus
                returnFocusAfterClose>
    <ModalHeader toggle={() => handleCancel()} className="h5" tag="h2">
      Save Changes
    </ModalHeader>
    <ModalBody>
      <Row>
        <Col className="col-12">
          Do you want to save changes before leaving this page?
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter>
      <Button color="success"
              className="mr-1"
              onClick={handleSave}>
        Save Changes
      </Button>
      <Button color="primary"
              onClick={() => handleDismiss()}>
        Dismiss
      </Button>
      <Button color="secondary"
              onClick={() => handleCancel()}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>;
};

export default UnsavedChangesWarningModal;