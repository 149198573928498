import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';

import {FormikNumberInput, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {formatInteger} from '../../utils';
import {form4046Schema} from '../../schemas';
import {AmendmentRequest, CountyFormDto, forms, ReturnRequest} from '../../types';
import {useUnsavedChangesWarning} from '../../hooks';

const tabs = [
  {value: 'realProperty', displayValue: 'Real Property'},
  {value: 'valuations', displayValue: 'Valuations'}
];

const sumRealProperty = (localUnit: forms.Form4046RealPropertyDto) => {
  const {
    agricultural,
    commercial,
    residential,
    industrial,
    timberCutover,
    developmental
  } = localUnit;
  return [agricultural, commercial, residential, industrial, timberCutover, developmental]
    .map((n) => isFinite(Number(n)) ? Number(n) : 0)
    .reduce((n1, n2) => n1 + n2);
};

const mapLocalUnit = (localUnit: forms.Form4046LocalUnitDto) => {
  localUnit.realProperty = localUnit.realProperty || {};
  localUnit.valuations = localUnit.valuations || {};
  return {
    id: localUnit.id,
    displayName: localUnit.displayName,
    realProperty: {
      agricultural: localUnit.realProperty.agricultural || '',
      commercial: localUnit.realProperty.commercial || '',
      residential: localUnit.realProperty.residential || '',
      industrial: localUnit.realProperty.industrial || '',
      timberCutover: localUnit.realProperty.timberCutover || '',
      developmental: localUnit.realProperty.developmental || ''
    },
    valuations: {
      personalProperty: localUnit.valuations.personalProperty || '',
      homeownerPrincipalResidence: localUnit.valuations.homeownerPrincipalResidence || '',
      commercialPersonalProperty: localUnit.valuations.commercialPersonalProperty || '',
      industrialPersonalProperty: localUnit.valuations.industrialPersonalProperty || '',
      nonHomesteadPersonalProperty: localUnit.valuations.nonHomesteadPersonalProperty || ''
    }
  };
};

const calculateTotals = (form4046: forms.Form4046Dto) => {
  let totals = {
    realProperty: {
      agricultural: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.agricultural)) ? Number(lu.realProperty.agricultural) : 0).reduce((n1, n2) => n1 + n2),
      commercial: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.commercial)) ? Number(lu.realProperty.commercial) : 0).reduce((n1, n2) => n1 + n2),
      residential: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.residential)) ? Number(lu.realProperty.residential) : 0).reduce((n1, n2) => n1 + n2),
      industrial: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.industrial)) ? Number(lu.realProperty.industrial) : 0).reduce((n1, n2) => n1 + n2),
      timberCutover: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.timberCutover)) ? Number(lu.realProperty.timberCutover) : 0).reduce((n1, n2) => n1 + n2),
      developmental: form4046.localUnits.map((lu) => isFinite(Number(lu.realProperty.developmental)) ? Number(lu.realProperty.developmental) : 0).reduce((n1, n2) => n1 + n2),
      all: 0
    },
    valuations: {
      personalProperty: form4046.localUnits.map((lu) => isFinite(Number(lu.valuations.personalProperty)) ? Number(lu.valuations.personalProperty) : 0).reduce((n1, n2) => n1 + n2),
      homeownerPrincipalResidence: form4046.localUnits.map((lu) => isFinite(Number(lu.valuations.homeownerPrincipalResidence)) ? Number(lu.valuations.homeownerPrincipalResidence) : 0).reduce((n1, n2) => n1 + n2),
      commercialPersonalProperty: form4046.localUnits.map((lu) => isFinite(Number(lu.valuations.commercialPersonalProperty)) ? Number(lu.valuations.commercialPersonalProperty) : 0).reduce((n1, n2) => n1 + n2),
      industrialPersonalProperty: form4046.localUnits.map((lu) => isFinite(Number(lu.valuations.industrialPersonalProperty)) ? Number(lu.valuations.industrialPersonalProperty) : 0).reduce((n1, n2) => n1 + n2),
      nonHomesteadPersonalProperty: form4046.localUnits.map((lu) => isFinite(Number(lu.valuations.nonHomesteadPersonalProperty)) ? Number(lu.valuations.nonHomesteadPersonalProperty) : 0).reduce((n1, n2) => n1 + n2),
      totalRealAndPersonal: 0
    }
  };

  totals.realProperty.all = Object.values(totals.realProperty).reduce((n1, n2) => n1 + n2);
  totals.valuations.totalRealAndPersonal = totals.valuations.personalProperty + form4046.localUnits.map(lu => sumRealProperty(lu.realProperty)).reduce((n1, n2) => n1 + n2);

  return totals;
};

type Props = {
  form: CountyFormDto
  loading: boolean
  isStateUser: boolean
  submitButtonText: string
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, countyFormData: forms.CountyFormData) => void
  onSubmit: (countyFormData: forms.CountyFormData) => void
  onResubmit: (countyFormData: forms.CountyFormData) => void
  onSave: (countyFormData: forms.CountyFormData, afterSave?: () => void) => Promise<void>
  onAccept: () => void
}

const Form4046 = ({
                    form,
                    loading,
                    onSave,
                    onSubmit,
                    onResubmit,
                    onAmend,
                    isStateUser,
                    submitButtonText,
                    onReturn,
                    onAccept
                  }: Props) => {
  const {selectedTab} = useTabNav();
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setResubmitModalIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModelIsOpen] = useState(false);
  
  // Use latest form submission data if available for state users
  const form4046Dto = useMemo(() => {
    if (isStateUser && form.latestSubmissionData !== null) {
      return form.latestSubmissionData as forms.Form4046Dto;
    } else {
      return form.data as forms.Form4046Dto;
    }
  }, [isStateUser, form]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4046' as const,
      localUnits: formikProps.values.localUnits
    };
  }, []);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModelIsOpen(!acceptModalIsOpen);
  }, [onAccept, acceptModalIsOpen]);

  const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      onSubmit(getSaveableData(formikProps));
    }

    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [onSubmit, getSaveableData, submitModalIsOpen]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      onResubmit(getSaveableData(formikProps));
    }
    setResubmitModalIsOpen(!resubmitModalIsOpen);
  }, [onResubmit, getSaveableData, resubmitModalIsOpen]);

  const handleAmend = useCallback((amendmentRequest: AmendmentRequest, formikProps: FormikProps<any>) =>
      onAmend(amendmentRequest, getSaveableData(formikProps)),
    [onAmend, getSaveableData]
  );

  const handleSave = useCallback((formikProps: FormikProps<any>,
                                  afterSave?: () => void) => {
    return onSave(getSaveableData(formikProps), afterSave);
  }, [
    onSave,
    getSaveableData
  ]);

  const initialValues = {
    localUnits: form4046Dto !== null && form4046Dto.localUnits.length > 0
      ? form4046Dto.localUnits.map(mapLocalUnit) : (form.localUnits.map((lu) => {
        return {
          ...lu,
          realProperty: {
            agricultural: '',
            commercial: '',
            residential: '',
            industrial: '',
            timberCutover: '',
            developmental: ''
          },
          valuations: {
            personalProperty: '',
            homeownerPrincipalResidence: '',
            commercialPersonalProperty: '',
            industrialPersonalProperty: '',
            nonHomesteadPersonalProperty: ''
          },
          displayName: lu.displayNameWithType
        };
      }))
  };

  const sumRealAndPersonalPropertyTaxableValuations = useCallback((formikProps: FormikProps<any>,
                                                                   index: number) => {
    const rawPersonalPropertyTotal = Number(formikProps.values.localUnits[index].valuations.personalProperty);
    const personalPropertyTotal = isFinite(rawPersonalPropertyTotal) ? rawPersonalPropertyTotal : 0;
    return personalPropertyTotal + sumRealProperty(formikProps.values.localUnits[index].realProperty);
  }, []);

  const shouldDisplayStateFormButtons = useMemo(() => isStateUser && form.status !== 'IN_PROGRESS', [isStateUser, form.status]);
  const showReadOnlyView = useMemo(() => isStateUser || form.locked, [isStateUser, form.locked]);

  return <div className="Form4046">
    <Formik initialValues={initialValues}
            validationSchema={form4046Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const totals = calculateTotals(formikProps.values as forms.Form4046Dto);
        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <CardBody>
              {selectedTab === 'realProperty' &&
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th className="align-middle text-primary">Township/City</th>
                      <th className="align-middle text-primary text-center">Agricultural</th>
                      <th className="align-middle text-primary text-center">Commercial</th>
                      <th className="align-middle text-primary text-center">Industrial</th>
                      <th className="align-middle text-primary text-center">Residential</th>
                      <th className="align-middle text-primary text-center">Timber-Cutover</th>
                      <th className="align-middle text-primary text-center">Developmental</th>
                      <th className="align-middle text-primary text-center">Total Real Property</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formikProps.values.localUnits.map((localUnit, index) => {
                      return <tr key={index}>
                        <td className="align-middle text-primary font-weight-bold text-nowrap">{localUnit.displayName}</td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.agricultural ?? 0)
                            : <FormikNumberInput formGroupClass="mb-0"
                                                 name={`localUnits[${index}].realProperty.agricultural`}
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Agricultural"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>
                          }
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.commercial ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].realProperty.commercial`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Commercial"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>
                          }
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.industrial ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].realProperty.industrial`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Industrial"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.residential ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].realProperty.residential`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Residential"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.timberCutover ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].realProperty.timberCutover`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Timber-Cutover"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].realProperty.developmental ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].realProperty.developmental`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Developmental"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {formatInteger(sumRealProperty(formikProps.values.localUnits[index].realProperty))}
                        </td>
                      </tr>;
                    })}
                  </tbody>
                  <tfoot className="bg-light">
                    <tr>
                      <td className="align-middle font-weight-bold text-primary">
                        Totals
                      </td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.agricultural)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.commercial)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.industrial)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.residential)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.timberCutover)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.developmental)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.realProperty.all)}</td>
                    </tr>
                  </tfoot>
                </Table>}
              {selectedTab === 'valuations' &&
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th className="align-middle text-primary">Township/City</th>
                      <th className="align-middle text-primary text-center">Personal Property Valuations</th>
                      <th className="align-middle text-primary text-center">Total Real and Personal Property Taxable Valuations</th>
                      <th className="align-middle text-primary text-center">Homeowner's Principal Residence & Qualified Agricultural & Qualified Forest Property Taxable Valuations</th>
                      <th className="align-middle text-primary text-center">Commercial Personal Property Taxable Valuations</th>
                      <th className="align-middle text-primary text-center">Industrial Personal Property Taxable Valuations</th>
                      <th className="align-middle text-primary text-center">Non-Homestead and Non-Qualified Agriculture and Non-Qualified Forest Personal Property Taxable Valuations except Commercial and Industrial</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formikProps.values.localUnits.map((localUnit, index) => {
                      return <tr key={index}>
                        <td className="align-middle text-primary font-weight-bold text-nowrap">{localUnit.displayName}</td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].valuations.personalProperty ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               ariaLabel="Personal Property Valuations"
                                               name={`localUnits[${index}].valuations.personalProperty`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {formatInteger(sumRealAndPersonalPropertyTaxableValuations(formikProps, index))}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].valuations.homeownerPrincipalResidence ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].valuations.homeownerPrincipalResidence`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Homeowner's Principal Residence & Qualified Agricultural & Qualified Forest Property Taxable Valuations"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].valuations.commercialPersonalProperty ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].valuations.commercialPersonalProperty`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Commercial Personal Property Taxable Valuations"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].valuations.industrialPersonalProperty ?? 0)
                            : <FormikNumberInput formGroupClass="mb-0"
                                                 name={`localUnits[${index}].valuations.industrialPersonalProperty`}
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Industrial Personal Property Taxable Valuations"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
                        </td>
                        <td className="align-middle text-center">
                          {showReadOnlyView ?
                            formatInteger(formikProps.values.localUnits[index].valuations.nonHomesteadPersonalProperty ?? 0) :
                            <FormikNumberInput formGroupClass="mb-0"
                                               name={`localUnits[${index}].valuations.nonHomesteadPersonalProperty`}
                                               maxLength="15"
                                               disableFloatingLabel={true}
                                               ariaLabel="Non-Homestead and Non-Qualified Agriculture and Non-Qualified Forest Personal Property Taxable Valuations except Commercial and Industrial"
                                               onChange={() => setHasUnsavedChanges(true)}
                                               className="text-center"/>}
                        </td>
                      </tr>;
                    })}
                  </tbody>
                  <tfoot className="bg-light">
                    <tr>
                      <td className="align-middle font-weight-bold text-primary">
                        Totals
                      </td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.personalProperty)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.totalRealAndPersonal)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.homeownerPrincipalResidence)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.commercialPersonalProperty)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.industrialPersonalProperty)}</td>
                      <td className="align-middle text-center font-weight-bold">{formatInteger(totals.valuations.nonHomesteadPersonalProperty)}</td>
                    </tr>
                  </tfoot>
                </Table>}
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {!isStateUser && <>
            <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps, afterSave)}/>

            <FormModals form={form}
                        resubmitFormModalIsOpen={resubmitModalIsOpen}
                        submitFormModalIsOpen={submitModalIsOpen}
                        amendModalIsOpen={commentModalIsOpen}
                        onSubmit={() => toggleSubmitModal(true, formikProps)}
                        onCancelSubmit={() => toggleSubmitModal(false)}
                        onResubmit={() => toggleResubmitModal(true, formikProps)}
                        onResubmitCancel={() => toggleResubmitModal(false)}
                        onAmend={(values) => handleAmend(values, formikProps)}
                        toggleAmend={() => toggleCommentModal()}/>
            <FormActionButtons submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || form.locked}
                               onSave={() => handleSave(formikProps)}
                               saveDisabled={loading || formikProps.isSubmitting || form.locked}
                               submitButtonText={submitButtonText}
                               onToggleCommentModal={() => toggleCommentModal()}
                               onToggleResubmitModal={() => toggleResubmitModal(false, formikProps)}
                               onToggleSubmitModal={() => toggleSubmitModal(false, formikProps)}/>
          </>}

          {shouldDisplayStateFormButtons && <>
            <StateFormButtons loading={loading || formikProps.isSubmitting}
                              onReturnClick={() => toggleCommentModal()}
                              onAcceptClick={() => toggleAcceptModal()}/>
            <StateFormModals onReturn={onReturn}
                             returnModalIsOpen={commentModalIsOpen}
                             onReturnCancel={() => toggleCommentModal()}
                             onAccept={() => toggleAcceptModal(true)}
                             acceptModalIsOpen={acceptModalIsOpen}
                             onAcceptCancel={() => toggleAcceptModal()}
                             form={form}/>
          </>}
        </>;
      }}
    </Formik>
  </div>;
};

export default withTabNav(Form4046, {tabs});