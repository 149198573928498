import {ReactNode, useCallback, useEffect, useState} from 'react';
import {UnsavedChangesWarningContext} from './UnsavedChangesWarningContext';
import {useBeforeUnload, useLocation} from 'react-router-dom';

type Props = {
  children: ReactNode
}

const UnsavedChangesProvider = ({
                                  children
                                }: Props) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHasUnsavedChanges(false);
    setIsWarningModalOpen(false);
  }, [location]);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      const message = 'Are you sure you want to go without saving changes?';

      if (message && e) {
        e.returnValue = message;
      }

      return message;
    }
  }, [
    hasUnsavedChanges
  ]);

  useBeforeUnload(handleBeforeUnload, {capture: true});

  return <UnsavedChangesWarningContext.Provider value={{
    hasUnsavedChanges,
    setHasUnsavedChanges,
    isWarningModalOpen,
    setIsWarningModalOpen
  }}>
    {children}
  </UnsavedChangesWarningContext.Provider>;
};

export default UnsavedChangesProvider;